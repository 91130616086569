import React from 'react';
import Section from 'components/Section/Section';
import Link from 'components/Link/Link';
import styles from './Account.module.scss';
import { Grid } from '@material-ui/core';
import AccountDetails from './AccountDetails/AccountDetails';
import MyFavorites from './MyFavorites/MyFavorites';
import RecentOrders from './RecentOrders/RecentOrders';
import CardsOnFile from './CardsOnFile/CardsOnFile';
import SavedAddresses from './SavedAddresses/SavedAddresses';
import GiftCardOnFile from './GiftCardOnFile/GiftCardOnFile';
import { useAppStateContext } from 'stateProviders/useAppStateContext';
import { useQueryUrlParamsDispatch } from 'hooks';
import useQueryUrlParams from 'hooks/useQueryUrlParams';
import useRetrieveBillingUserAccounts from 'modules/Checkout/CheckoutOrder/PaymentMethod/SavedCreditCard/hooks/useRetrieveBillingUserAccounts';
import QualifiedVisitHistory from './QualifiedVisitHistory/QualifiedVisitHistory';

const Account = () => {
  const containerRef = React.createRef();
  const { openOrderHistory } = useQueryUrlParams();
  const parsedOpenOrderHistory = openOrderHistory ? !!JSON.parse(openOrderHistory) : false;
  const { billingAccounts, loading: loadingAccounts, error: errorAccounts } = useRetrieveBillingUserAccounts();

  const {
    user: { data: userData },
    restaurant: { data },
  } = useAppStateContext();
  const setRedirect = useQueryUrlParamsDispatch();

  const toMenu = () => {
    if (data && data.id) {
      setRedirect(null, `/${data.id}/menu`, false, ['restaurantId']);
    } else {
      setRedirect({ location: true }, '/', false, ['restaurantId']);
    }
  };

  const myFavoritesLoaded = () => {
    if (parsedOpenOrderHistory) {
      const parentContainerOffsetTop = containerRef.current.parentElement.offsetTop;
      const recentOrders = sectionsConfig.find((section) => {
        return section.id && section.id === 'recentOrders' && section.ref;
      });
      if (recentOrders && recentOrders.ref.current) {
        const recentOrdersOffsetTop = recentOrders.ref.current.offsetTop;
        window.scrollTo(0, recentOrdersOffsetTop - parentContainerOffsetTop);
      }
    }
  };

  const sectionsConfig = [
    { title: 'FAVORITE ORDERS', children: <MyFavorites toMenu={toMenu} dataLoaded={myFavoritesLoaded} /> },
    {
      id: 'recentOrders',
      title: 'RECENT ORDERS',
      children: <RecentOrders toMenu={toMenu} />,
      ref: React.createRef(),
    },
    // {
    //   id: 'qualifiedVisitHistory',
    //   title: 'Qualified Visit History',
    //   children: <QualifiedVisitHistory />,
    //   description: (
    //     <span className={styles.quialified_history_description}>
    //       A visit qualifies when you spend $9.99 or more in restaurant or online.
    //     </span>
    //   ),
    //   ref: React.createRef(),
    // },
    {
      title: 'CARDS ON FILE',
      children: (
        <CardsOnFile
          billingAccounts={billingAccounts}
          loading={loadingAccounts}
          error={errorAccounts}
          toMenu={toMenu}
        />
      ),
    },
    {
      title: 'GIFT CARDS',
      description: (
        <Link
          color="primary"
          target="_blank"
          href={process.env.REACT_APP_PATH_CHECK_GIFT_CARD}
          className={styles.section_link}
        >
          Check your Gift card Balance
        </Link>
      ),
      children: (
        <GiftCardOnFile
          billingAccounts={billingAccounts}
          loading={loadingAccounts}
          error={errorAccounts}
          toMenu={toMenu}
        />
      ),
    },
    {
      title: 'SAVED ADDRESSES',
      children: <SavedAddresses toMenu={toMenu} />,
    },
  ];

  if (!userData) return null;

  const sections = sectionsConfig.map(({ ref, title, titleElem, description, children }, i) => (
    <Grid container spacing={2} key={i} ref={ref}>
      <Grid item xs={12}>
        <div className={styles.account_section_wrapper}>
          <Section sectionHr title={title} titleElem={titleElem} description={description}>
            {children}
          </Section>
        </div>
      </Grid>
    </Grid>
  ));

  return (
    <div className={styles.account_container} ref={containerRef}>
      <div className={styles.account_details_wrapper}>
        <AccountDetails />
      </div>
      <div className={styles.account_sections_wrapper}>{sections}</div>
    </div>
  );
};

export default React.memo(Account);
